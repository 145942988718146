<template>
  <div class="files">
    <div class="files-wrapper">
      <v-simple-table class="table"
        ><template v-slot:default>
          <thead>
            <tr>
              <th class="sticky-header text-left">
                Имя файла
              </th>
              <th class="sticky-header text-left">
                Формат файла
              </th>
              <th class="sticky-header text-left">
                Файл обработан
              </th>
              <th class="sticky-header text-left">
                Ошибка
              </th>
              <th class="sticky-header text-left">
                Кол-во контрагентов
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in files.contractors_files" :key="item.id">
              <td>
                {{ item.filename }}
              </td>
              <td>{{ item.file_format }}</td>
              <td>
                {{ item.is_processed ? "Да" : "Нет" }}
              </td>
              <td>
                {{ item.has_errors ? "Да" : "Нет" }}
              </td>
              <td>
                {{ item.contractors_count }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["files"],
};
</script>

<style lang="scss" scoped>
.files {
  &-wrapper {
    background: white;
    margin: 7px 0;
  }
  th {
    padding: 7px 15px !important;
    text-transform: uppercase;
    font-size: 10px !important;
  }
  td {
    font-size: 12px !important;
  }
}
</style>
